import React, { memo, useCallback, useEffect, useState } from "react";
import { useAsyncFn } from "react-use";
import styled from "styled-components";
import { Alert, Button, Switch } from "antd";
import dayjs from "dayjs";
import {
  collection,
  doc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  runTransaction,
  setDoc,
  where,
} from "firebase/firestore";

import { MainLayout } from "components/Layout/MainLayout";
import { ShopSelector } from "components/ShopSelector";
import { Spacer } from "components/Spacer";
import { useSearchParams } from "hooks/useSearchParams";
import { PrintTarget, PrintTargetTable } from "pages/PrintTargets/PrintTargetTable";
import {
  usePrintTargetsGetRolesQuery,
  usePrintTargetsSetPrintTargetIsPrintedMutation,
} from "pages/PrintTargets/queries";

import { firestore } from "../../libs/firebase";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 16px 0;
`;

export const PrintTargets = memo(() => {
  const [targets, setTargets] = useState<PrintTarget[]>([]);
  const { getSearchParam } = useSearchParams<"shopId">();
  const shopId = getSearchParam("shopId");
  const [loading, setLoading] = useState(false);
  const [onlyUnprinted, setOnlyUnprinted] = useState(false);

  const { data: getRolesData } = usePrintTargetsGetRolesQuery(
    shopId ? { variables: { shopId } } : { skip: true },
  );
  const roles = getRolesData?.role ?? [];
  const [setPrintTargetIsPrintedMutation] = usePrintTargetsSetPrintTargetIsPrintedMutation();

  const setIsPrinted = useCallback(
    async (printTarget: PrintTarget, isPrinted: boolean) => {
      try {
        const { errors } = await setPrintTargetIsPrintedMutation({
          variables: {
            input: {
              printTargetId: printTarget.printTargetId,
              shopId: printTarget.shopId,
              roleId: printTarget.roleId,
              isPrinted,
            },
          },
        });
        if (errors) {
          const errorMessage = ["エラーが発生しました:", ...errors.map((e) => e.message)].join(
            "\n",
          );
          window.alert(errorMessage);
        }
      } catch (e) {
        window.alert(e);
      }
    },
    [setPrintTargetIsPrintedMutation],
  );

  const [{ loading: isDeleting }, deleteAll] = useAsyncFn(async () => {
    if (!shopId) return;

    const documents = await getDocs(
      collection(doc(collection(firestore, "shops"), shopId), "printTargets"),
    );

    await runTransaction(firestore, async (transaction) => {
      documents.forEach((snapshot) => transaction.delete(snapshot.ref));
    });
  }, [shopId]);

  useEffect(() => {
    if (!shopId) return;

    setLoading(true);

    const unsubscribe = onSnapshot(
      query(
        collection(doc(collection(firestore, "shops"), shopId), "printTargets"),
        orderBy("createdAt", "desc"),
        where("createdAt", ">=", dayjs().subtract(3, "d").toDate()),
      ),
      (snapshot) => {
        setLoading(false);
        const logs = snapshot.docs.map((value) => value.data() as PrintTarget);
        setTargets(logs.filter((l) => (onlyUnprinted ? !l.isPrinted : true)));
      },
      (err) => {
        setLoading(false);
        // eslint-disable-next-line @typescript-eslint/no-base-to-string
        console.error(`Encountered error: ${err}`);
      },
    );
    return unsubscribe;
  }, [onlyUnprinted, shopId]);

  const notifyAgain = useCallback(async (printTarget: PrintTarget) => {
    try {
      await setDoc(
        doc(
          collection(doc(collection(firestore, "shops"), printTarget.shopId), "printTargets"),
          printTarget.printTargetId,
        ),
        { revision: (printTarget.revision ?? 0) + 1 },
        { merge: true },
      );
    } catch (e) {
      window.alert(e);
    }
  }, []);

  return (
    <MainLayout title="印刷対象監視">
      <ShopSelector />

      <Spacer size={16} />

      <Alert
        type="info"
        message="1 日前までのログをリアルタイムで表示します。また、プレビューは実際の印刷物とは異なる可能性があります。"
      />

      <Container>
        <Switch
          checkedChildren="印刷済みを含む"
          unCheckedChildren="未印刷のみ"
          checked={!onlyUnprinted}
          onClick={() => setOnlyUnprinted((onlyUnprinted) => !onlyUnprinted)}
        />

        <Spacer size={16} />

        {import.meta.env.APP_ENV !== "production" && shopId && (
          <Button loading={isDeleting} onClick={deleteAll}>
            すべて削除
          </Button>
        )}
      </Container>

      <PrintTargetTable
        loading={loading}
        printTargets={targets}
        roles={roles}
        setIsPrinted={setIsPrinted}
        notifyAgain={notifyAgain}
      />
    </MainLayout>
  );
});
